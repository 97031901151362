<template>
	<div class="data-list list-tab-show-page">
		<template v-if="loaded">
			<div class="row-space-tbf header-list">
				<div class="space-left"></div>
				<div class="content">
					<div class="column-filter column-name">
						<div class="text">{{ $t('general.name') }}</div>
					</div>
					<div v-if="$resize && $mq.above(650)" class="column-filter column-version-name">
						<div class="text">{{ $t('show_user.processes_active_version') }}</div>
					</div>
					<div v-if="$resize && ($mq.above(1235) || $mq.between([400,1024]))" class="column-filter column-update_requests">
						<div class="text">{{ $t('show_user.process_update_requests') }}</div>
					</div>
					<div class="column-filter column-user" v-if="$resize && ($mq.above(1130) || $mq.between([500, 1024]))"></div>
				</div>
				<div class="space-right"></div>
			</div>

			<template v-if="entries.length">
				<div class="row-space-tbf row-list-item" :key="'procedura' + entry.id" v-for="entry in entries">
					<div class="space-left"><icon-procedures v-if="$resize && $mq.above(781)"/></div>
					<div class="content">
						<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
						<div class="column-name-title column-name">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
								<div class="circle-status" :class="{active: entry.status == 'live'}"></div>
								<div class="name link" @click="goItem(entry.slug)">{{ entry.name }}</div>

								<template slot="popover">
									<div class="simple-text">{{ entry.description }}</div>
								</template>
							</v-popover>
						</div>
						<div v-if="$resize && ($mq.above(1115) || $mq.between([600,1024]))" class="column-simple-text column-version-name">
							<div class="text">{{ entry.publish_version ? entry.publish_version.name : '' }}</div>
						</div>
						<div v-if="$resize && ($mq.above(1235) || $mq.between([400,1024]))" class="column-simple-text column-update_requests">
							<div class="text">{{ entry.improvement_proposals.active }}</div>
						</div>
						<div v-if="$resize && ($mq.above(1130) || $mq.between([500, 1024]))" class="column-avatar column-user">
							<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover" :delay="{show: 0, hide: 0}">
								<img :src="entry.responsible.avatar" v-if="entry.responsible.avatar">
								<div class="user-circle" v-else>
									<icon-user />
								</div>

								<template slot="popover">
									<div class="simple-text">{{ entry.responsible.first_name }} {{ entry.responsible.last_name }}</div>
								</template>
							</v-popover>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</template>
			<template v-else>
				<div class="row-space-tbf row-list-item">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-empty-list">
							<div class="name"><img src="/build/images/no-results-found-inline.svg">{{ $t('congratulations.empty-inline', {name: userName }) }}</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</template>
		</template>
		<loader-items-list-2 v-else/>
	</div>
</template>


<script>
	import IconUser from '../../Icons/User'
	import IconEdit from '../../Icons/EditDots'
	import IconProcedures from '../../Icons/Procedures'
	import IconEditMobile from '../../Icons/Edit'
	import LoaderItemsList2 from '../../PagesLoaders/ItemsList2'
	import StarRating from 'vue-star-rating'

	export default {
		components: {
			IconUser,
			IconEdit,
			IconEditMobile,
			IconProcedures,
			LoaderItemsList2,
			StarRating
		},
		data() {
			return {
				loaded: false,
				entries: []
			}
		},
		props: {
			userName: String,
			userSlug: String,
			dateRange: Object
		},
		watch:{
			$route (to, from){
				this.getProceduresEntries()
			}
		},
		async mounted(){
			await this.getProceduresEntries()
		},
		methods: {
			async getProceduresEntries(){
				let paramsRequest = { 
					start_date: moment(this.dateRange.startDate).format('DD-MM-YYYY'),
					end_date: moment(this.dateRange.endDate).format('DD-MM-YYYY')
				}

				await axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/users/${this.userSlug}/processes`, {params: paramsRequest })
				.then(({data}) => {
					this.entries = data.data
				})
				.finally(() => {
					setTimeout(() => { this.loaded = true }, 0)
				})
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			goItem(itemSlug){
				window.open(`https://proceduri.tbf.ro/procedure/${itemSlug}`, '_blank');
			}
		}
	}
</script>