<template>
	<div class="content">
		<div class="evaluation">
			<div class="header-user">
				<div class="data">
					<div class="name">{{ this.activeForm == 'congratulations' ? $t('user-details.title-congratulation') : $t('user-details.title-warning') }} {{ `${user.first_name} ${user.last_name ? user.last_name : ''}` }}</div>
				</div>

				<div class="image">
					<img :src="user.avatar" v-if="user.avatar">
					<div class="user-circle" v-else>
						<icon-user />
					</div>
				</div>
			</div>
			<div class="add-evaluation-box bb-0">
				<div class="form-group">
					<div class="form">
	                    <div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{$t('user-details.responsibilities')}}*</label>
							</div>
							<div class="input-box" v-bind:class="{has_error: $v.selectedResponsibility.$error}">
								<div class="icon-left"><icon-list /></div>
								<multiselect 
								v-model="selectedResponsibility"
								:options="responsibilities"
								:allow-empty="false"
								:show-labels="false"
								:multiple="false" 
								:close-on-select="true"
								track-by="id"
								label="name"
								group-values="responsibilities"
								group-label="role"
								:group-select="false"
								:disabled="!selectedUser"
								>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{$t('user-details.choose_responsibility')}}
										</span>
									</template>
									<template slot="noResult">{{ $t('general.no_result') }}</template>
									<template slot="noOptions">{{ $t('general.empty_list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t('user-details.description') }}*</label>
							</div>
							<div class="input-box" v-bind:class="{has_error: $v.description.$error}">
								<textarea v-model="description" class="input-text" rows="10"></textarea>
							</div>
						</div>
					</div>
				</div>

				
				<div class="form-submit">
					<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
					<button class="btn-tbf grey" @click="backRoute()"><span class="text">{{ $t('general.cancel') }}</span></button>
					<button id="buttonCreate" class="btn-tbf blue" @click="saveAction()">
						<div class="loader"></div>
						<span class="text">{{ $t('general.add') }}</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconArrowBack from '../../Icons/ArrowLong'
	import IconClose from '../../Icons/Close'
    import IconPeople from '../../Icons/People'
	import IconList from '../../Icons/Responsibility'
	import IconUser from '../../Icons/User'
	import LoaderUsersCreate from '../../PagesLoaders/UsersCreate'
	import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconArrowBack,
			IconUser,
			IconClose,
            IconPeople,
			IconList,
			LoaderUsersCreate
		},
		data() {
			return {
				loaded: false,
                error_message: '',
				description: '',
                users: [],
				selectedUser: '',
                responsibilities: [],
				selectedResponsibility: '',
			};
		},
		props: {
			user: Object,
			activeForm: String
		},
		async mounted(){
			this.selectedUser = this.user;
			this.getResponsabilities();
		},
		validations: {
            description: {required},
            selectedUser: {required},
            selectedResponsibility: {required}
		},
		methods: {
			backRoute() {
				this.$emit('closeForm', '');
				if(this.activeForm == 'congratulations'){
					this.$root.$emit('refreshCongratulationsIndex');
				}
				if(this.activeForm == 'warnings'){
					this.$root.$emit('refreshWarningsIndex');
				}
			},
			saveAction(){
				var buttonName = `buttonCreate`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					let objData = {}
					objData.user_id = this.selectedUser.id
                    objData.body = this.description
                    objData.role_responsibility_id = this.selectedResponsibility.id
                    objData.type = this.activeForm

					this.submitStore(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML =this.$t('general.add')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            submitStore(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/reviews/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							this.backRoute()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			getResponsabilities(){
				axios.get(`instances/${this.$auth.user().instance.id}/filter`, { params: {user_responsibilities: true, userId: this.user.id} })
        		.then(({data}) => {
        			this.responsibilities = data.data.responsibilities
        			this.loaded = true
        		})
			}
		}
	};
</script>