<template>
	<div class="content">	
		<div class="evaluation">
			<div class="header-user">
				<div class="data">
					<div class="name">{{ $t('assessments.title') }} {{ `${user.first_name} ${user.last_name ? user.last_name : ''}` }}</div>
				</div>

				<div class="image">
					<img :src="user.avatar" v-if="user.avatar">
					<div class="user-circle" v-else>
						<icon-user />
					</div>
				</div>
			</div>
			<div class="add-evaluation-box">
				<div class="form-group">
					<div class="form">
			            <div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t('assessments.good_things') }}*</label>
							</div>
							<div class="input-box" v-bind:class="{has_error: $v.evaluation.good_things.$error}">
								<div class="icon-right" v-if="evaluation.good_things != ''" @click.stop="evaluation.good_things = ''"><icon-close class="icon-clear" /></div>
			                    <textarea v-model="evaluation.good_things" class="input-text" name="" id="" cols="30" rows="3"></textarea>
							</div>
						</div>
			            <div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t('assessments.what_to_change') }}</label>
							</div>
							<div class="input-box" v-bind:class="{has_error: $v.evaluation.what_to_change.$error}">
								<div class="icon-right" v-if="evaluation.what_to_change != ''" @click.stop="evaluation.what_to_change = ''"><icon-close class="icon-clear" /></div>
			                    <textarea v-model="evaluation.what_to_change" class="input-text" name="" id="" cols="30" rows="3"></textarea>
							</div>
						</div>
			            <div class="input-group w-100">
							<div class="label-header">
								<label class="label">{{ $t('assessments.stars') }}</label>
							</div>
							<div class="">
								<star-rating v-model:rating="evaluation.stars" 
											:show-rating="false"
											:star-size=25
											:increment=0.5>
								</star-rating>
							</div>
						</div>
						<div class="form-submit">
							<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
							<button class="btn-tbf grey" @click="backRoute()"><span class="text">{{ $t('general.cancel') }}</span></button>
							<button id="buttonCreate" class="btn-tbf blue" @click="saveAction()">
								<div class="loader"></div>
								<span class="text">{{ $t('assessments.add') }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>

			<template v-if="user.roles.length">
				<div class="subtitle-sidebar">{{ $t('profile.roles') }}</div>
				
				<div class="box-label-text" v-for="role in user.roles">
					<div class="label">{{ role.name }}</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import { required, requiredIf } from 'vuelidate/lib/validators'
	import StarRating from 'vue-star-rating'
	import IconClose from '../../Icons/Close'
	import IconUser from '../../Icons/User'

	export default {
		components: {
			StarRating,
			IconClose,
			IconUser
		},
		props: {
			user: Object,
		},
		data() {
			return {
				loaded: true,
                evaluation: {
                    good_things: '',
                    what_to_change: '',
                    stars: 5,
                },
                error_message: ''
			};
		},
		mounted(){
			console.log(this.user)

		},
		validations: {
            evaluation: {
                good_things: {required},
                stars: {required},
				what_to_change: {required}
			},
		},
		methods: {
			saveAction(){
				var buttonName = `buttonCreate`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';
				this.$v.$touch()
				if(!this.$v.$invalid){
					this.createEvaluation(this.evaluation, btnSubmit, btnSubmitText, btnSubmitLoader)

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML =this.$t('general.add')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            createEvaluation(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
            	objData.user_id = this.user.id;
				axios.post('/user-evaluations/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							this.backRoute()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			backRoute() {
				this.$emit('closeForm', '');
				this.$root.$emit('refreshEvaluationsIndex');
			},
		}
	};
</script>