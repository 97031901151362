<template>
	<div class="page-show-item change_responsive show-user" v-if="loaded">
		<div class="main-content">
			<div class="header-with-tabs row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div v-if="$resize && ($mq.above(1025) && !$mq.between([1025, 1725]))" class="tabs">
						<div class="title-tab" :class="{active: activeTab == 'responsibilities'}" @click="activeTab = 'responsibilities', changeTab(activeTab)">{{ $t('show_user.tab_responsibilities') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'congratulations'}" @click="activeTab = 'congratulations', changeTab(activeTab)">{{ $t('show_user.tab_congratulations') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'warnings'}" @click="activeTab = 'warnings', changeTab(activeTab)">{{ $t('show_user.tab_warnings') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'assessments'}" @click="activeTab = 'assessments', changeTab(activeTab)">{{ $t('show_user.tab_assessments') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'objectives'}" @click="activeTab = 'objectives', changeTab(activeTab)" v-if="activeObiective">{{ $t('show_user.tab_objectives') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'procedures'}" @click="activeTab = 'procedures', changeTab(activeTab)" v-if="activeProceduri">{{ $t('show_user.tab_procedures') }}</div>
					</div>
					<div v-if="$resize && ($mq.below(1024) || $mq.between([1025, 1725]))" class="actions-dropdown">
						<div class="filter-dropdown dropdown first">
							<button class="btn-tbf white drop" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text">{{ $t(`show_user.tab_${activeTab}`) }}</span>
								<div class="icon-filter"><icon-arrow /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="dropdown-item" :class="{active: activeTab == 'responsibilities'}" @click="activeTab = 'responsibilities', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_responsibilities') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'list_responsibilities'}" @click="activeTab = 'list_responsibilities', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_list_responsibilities') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'congratulations'}" @click="activeTab = 'congratulations', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_congratulations') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'warnings'}" @click="activeTab = 'warnings', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_warnings') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'assessments'}" @click="activeTab = 'assessments', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_assessments') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'objectives'}" @click="activeTab = 'objectives', changeTab(activeTab)" v-if="activeObiective">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_objectives') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'key_results'}" @click="activeTab = 'key_results', changeTab(activeTab)" v-if="activeObiective">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_key_results') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'promises'}" @click="activeTab = 'promises', changeTab(activeTab)" v-if="activeObiective">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_promises') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'procedures'}" @click="activeTab = 'procedures', changeTab(activeTab)" v-if="activeProceduri">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_procedures') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'processes'}" @click="activeTab = 'processes', changeTab(activeTab)" v-if="activeProceduri">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_processes') }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="actions">
						<div v-if="$resize && $mq.above(500)" class="actions-dropdown">
							<div v-if="['objectives', 'key_results', 'promises'].includes(activeTab)" class="filter-dropdown dropdown second">
								<button class="btn-tbf white drop" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text">{{ $t(`show_user.tab_${activeTab}`) }}</span>

									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div class="dropdown-item" :class="{active: activeTab == 'objectives'}" @click="activeTab = 'objectives', changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_objectives') }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{active: activeTab == 'key_results'}" @click="activeTab = 'key_results', changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_key_results') }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{active: activeTab == 'promises'}" @click="activeTab = 'promises', changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_promises') }}</span>
										</div>
									</div>
								</div>
							</div>

							<div v-if="['processes', 'procedures'].includes(activeTab)" class="filter-dropdown dropdown third">
								<button class="btn-tbf white drop" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text">{{ $t(`show_user.tab_${activeTab}`) }}</span>

									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div class="dropdown-item" :class="{active: activeTab == 'processes'}" @click="activeTab = 'processes', changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_processes') }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{active: activeTab == 'procedures'}" @click="activeTab = 'procedures', changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t('show_user.tab_procedures') }}</span>
										</div>
									</div>
								</div>
							</div>

							<div v-if="['responsibilities', 'list_responsibilities'].includes(activeTab)" class="filter-dropdown dropdown">
								<button class="btn-tbf white drop-roles" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text" v-if="!selectedRoles.length">{{ $t('filters.by_roles') }}</span>
									<span class="text" v-else-if="selectedRoles.length == 1">{{ filterRoles.find(el => el.id == selectedRoles[0]).name }}</span>
									<span class="text" v-else>{{ selectedRoles.length }} {{ $t('filters.roles_selected') }}</span>

									<div class="icon-filter" v-if="!selectedRoles.length"><icon-arrow /></div>
									<div class="icon-clear" @click.stop="clearFilter('selectedRoles')" v-else><icon-close /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<template v-for="role in user.roles">
										<div class="dropdown-item" @click.stop="selectOptionFilter('selectedRoles', role.id)" v-bind:class="{active: selectedRoles.includes(role.id)}" :key="'filter-role-' + role.id">
											<div class="checkbox">
												<div class="checkmark"></div><span class="text">{{ role.name }}</span>
											</div>
										</div>
									</template>
								</div>
							</div>

							<div v-if="['responsibilities', 'list_responsibilities'].includes(activeTab)" class="filter-dropdown dropdown">
								<button class="btn-tbf white drop" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<span class="text">{{ $t(`show_user.custom_type_responsibilities.${activeTab}`) }}</span>
									<div class="icon-filter"><icon-arrow /></div>
								</button>
								<div class="dropdown-menu" aria-labelledby="dropdownFilter">
									<div class="dropdown-item" :class="{active: activeTab == 'responsibilities'}" @click="activeTab = 'responsibilities', changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t('show_user.custom_type_responsibilities.responsibilities') }}</span>
										</div>
									</div>
									<div class="dropdown-item" :class="{active: activeTab == 'list_responsibilities'}" @click="activeTab = 'list_responsibilities', changeTab(activeTab)">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ $t('show_user.custom_type_responsibilities.list_responsibilities') }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="filter-btns" :class="{'mr-0': !user.rights.evaluation_review_crud || !['congratulations', 'warnings', 'assessments'].includes(activeTab)} " v-if="!['responsibilities', 'list_responsibilities'].includes(activeTab)">
							<date-range-picker
								ref="picker"
								class="calendar-filter"
								:class="[{mobile: $resize && ($mq.below(500) || $mq.between([1431, 1580]) || $mq.between([1025, 1140]))}]"
								:opens="$resize && $mq.between([1025, 1342]) ? 'center' : 'left'"
								:locale-data="calendarFilterSettings"
								:singleDatePicker="$mq.below(650) ? 'range' : false"
								:showWeekNumbers="false"
								:showDropdowns="false"
								:ranges="false"
								:autoApply="true"
								:linkedCalendars="true"
								:maxDate="maxDateCalendar"
								v-model="dateRange"
								>
								<template v-slot:input="picker">
									<div class="input-picker">
										<div class="icon"><icon-date/></div>
										<div class="date" v-if="picker.startDate == picker.endDate">{{ picker.startDate | moment('DD MMM YY') }}</div>
										<div class="date" v-else>{{ picker.startDate | moment('DD MMM YY') }} - {{ picker.endDate | moment('DD MMM YY') }}</div>
										<icon-arrow class="icon-arrow right"/>
									</div>
								</template>
							</date-range-picker>
						</div>
						<button class="btn-tbf blue show" :class="{mobile: $resize && $mq.below(1254)}" @click="goCreateEntry(activeTab)" v-if="user.rights.evaluation_review_crud && ['congratulations', 'warnings', 'assessments'].includes(activeTab)">
							<div class="icon">
								<icon-plus />
							</div>
							<span v-if="$resize && $mq.above(1255)" class="text">{{ $t('general.add') }}</span>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="tab-content">
				<tab-responsibilities v-if="activeTab == 'responsibilities'" :data="{slug: user.slug, id: user.id, right: user.rights.evaluation_review_crud, from: 'user'}"/>
				<tab-list-responsibilities v-if="activeTab == 'list_responsibilities'" :data="{slug: user.slug, id: user.id, from: 'user'}"/>
				<tab-congratulations v-if="activeTab == 'congratulations'" :dateRange="dateRange" :userSlug="user.slug" :userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`" :userRight="user.rights.evaluation_review_crud" />
				<tab-warnings v-if="activeTab == 'warnings'" :dateRange="dateRange" :userSlug="user.slug" :userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`" :userRight="user.rights.evaluation_review_crud" />
				<tab-assessments v-if="activeTab == 'assessments'" :dateRange="dateRange" :userSlug="user.slug" :userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`" :userRight="user.rights.evaluation_review_crud" />
				<tab-promises v-if="activeTab == 'promises'" :dateRange="dateRange" :userSlug="user.slug" :userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`" />
				<tab-objectives v-if="activeTab == 'objectives'" :dateRange="dateRange" :userSlug="user.slug" :userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`" />
				<tab-key-results v-if="activeTab == 'key_results'" :dateRange="dateRange" :userSlug="user.slug" :userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`" />
				<tab-procedures v-if="activeTab == 'procedures'" :dateRange="dateRange" :userSlug="user.slug" :userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`" />
				<tab-processes v-if="activeTab == 'processes'" :dateRange="dateRange" :userSlug="user.slug" :userName="`${user.first_name} ${user.last_name ? user.last_name : ''}`" />
			</div>
		</div>
		<div class="sidebar-user">
			<div class="space-left-custom"></div>
			<add-evaluation v-if="activeForm == 'assessments'" :user="user"  @closeForm="goCreateEntry"/>
			<add-congratulation-and-warning v-else-if="activeForm == 'congratulations' || activeForm == 'warnings'" :user="user"  :activeForm="activeForm" @closeForm="goCreateEntry"/>
			<div class="content" v-else>
				<div class="actions-header" v-if="$auth.user().rights.admins_crud || user.rights.delete">
					<button @click="$router.push({ name: 'user-edit', params: {slug: user.slug} })" v-if="$auth.user().rights.admins_crud">
						<div class="text ">{{ $t('general.edit') }}</div>
					</button>

					<button class="delete-btn" v-if="user.rights.delete" @click="showModal('delete', {type: 'user', from: 'show_user', model: user})">
						<div class="text ">{{ $t('general.delete') }}</div>
					</button>
				</div>

				<div class="header-user">
					<div class="data">
						<div class="name">{{ `${user.first_name} ${user.last_name ? user.last_name : ''}` }}</div>
						<div class="tag" v-if="!user.dummy_account">{{ user.email }}</div>
					</div>

					<div class="image">
						<img :src="user.avatar" v-if="user.avatar">
						<div class="user-circle" v-else>
							<icon-user />
						</div>
					</div>
				</div>

				<div class="box-label-text" v-if="user.roles.length">
					<div class="label">{{ $t('general.roles') }}</div>
					<div class="text">{{ user.roles.map(a => a.name).join(", ") }}</div>
				</div>

				<div class="box-label-text" v-if="user.departments.length">
					<div class="label">{{ $t('profile.departments') }}</div>
					<div class="text">{{ user.departments.join(", ") }}</div>
				</div>

				<div class="work-today" @click="showModal('user_free_days', {user_id: user.id, user_name: `${user.first_name} ${user.last_name ? user.last_name : ''}`})">
					<div class="circle-status" :class="[ user.free_day ? 'red' : 'green']"></div>
					<div class="text">{{ user.free_day ? $t('days_off.user_free_day') : $t('days_off.user_work_day') }}</div>
				</div>

				<div class="accordion-sidebar" v-if="activeObiective">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapsePromiseObjective">
						<div class="icon"><div class="circle-status" :class="{red: ['not_completed', 'not_set'].includes(promiseObjective.status), yellow: ['pending'].includes(promiseObjective.status), green: ['completed'].includes(promiseObjective.status), grey: ['no_key_result', 'not_working'].includes(promiseObjective.status) || !promiseObjective.ready}"></div></div>

						<div class="title">{{ $t('promise_objectives.title') }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapsePromiseObjective" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapsePromiseObjective">
						<div class="box-radius">
							<div class="data">
								<div class="value" v-if="promiseObjective.name">{{ promiseObjective.name }}</div>
								<div class="value" v-else-if="promiseObjective.status == 'not_set'">{{ $t('promise_objectives.status.not_set') }}</div>
								<div class="value" v-else-if="promiseObjective.status == 'not_working'">{{ $t('promise_objectives.status.not_working') }}</div>
								<div class="value" v-else-if="promiseObjective.status == 'no_key_result'">{{ $t('promise_objectives.status.no_key_result') }}</div>
							</div>
							
							<div class="data" v-if="promiseObjective.why_not">
								<div class="label">{{ $t('promise_objectives.why_not') }}</div>
								<div class="value">{{ promiseObjective.why_not }}</div>
							</div>

							<div class="data" v-if="promiseObjective.key_results_names && promiseObjective.key_results_names.length">
								<div class="label">{{ $t('promise_objectives.key_results') }}</div>
								<div class="value">
									<div class="list-item" v-for="kr in promiseObjective.key_results_names">{{ kr }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="user.work_schedule">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseWorkSchedules">
						<div class="icon"><icon-work-schedule /></div>

						<div class="title">{{ $t('show_user.work-schedule') }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseWorkSchedules" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseWorkSchedules">
						<div class="box-radius">
							<div class="inline-data" v-for="schedule in user.work_schedule.schedules">
								<div class="label">{{ $t(`general.${schedule.day}`) }}</div>
								<div class="value">{{ `${schedule.start_time} - ${schedule.end_time}` }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="user.skills.length">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseSkills">
						<div class="icon"><icon-skill /></div>

						<div class="title">{{ $t('show_user.skills') }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseSkills" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseSkills">
						<div class="box-radius">
							<div class="inline-data" v-for="skill in user.skills">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="label" :delay="{show: 100, hide: 0}" >
									<div class="label">{{ skill.name }}</div>
									<template slot="popover">
										<div class="simple-text">{{ skill.description }}</div>
									</template>
								</v-popover>

								<div class="value">{{ $t(`skill.type.${skill.level}`) }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="user.foreign_languages.length">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseForeignLanguages">
						<div class="icon"><icon-globe /></div>

						<div class="title">{{ $t('show_user.foreign_languages') }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseForeignLanguages" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseForeignLanguages">
						<div class="box-radius">
							<div class="data" v-for="foreign_language in user.foreign_languages">
								<div class="label">{{ foreign_language.name }}</div>
								<div class="value">{{ foreign_language.levels.map((el) => { return `${ $t('foreign_languages.' + el['type'])}: ${el['level']}`}).join(", ") }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapsePersonalData">
						<div class="icon"><icon-user /></div>

						<div class="title">{{ $t('show_user.user-details') }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapsePersonalData" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapsePersonalData">
						<div class="box-radius">
							<div class="inline-data">
								<div class="label">{{ $t('profile.rights') }}</div>
								<div class="value">{{ $t(`profile.rights_name.${user.right}`) }}</div>
							</div>
							<div class="inline-data" v-if="user.business_phone">
								<div class="label">{{ $t('profile.phone') }}</div>
								<div class="value">{{ user.phone }}</div>
							</div>
							<div class="inline-data" v-if="user.workstation">
								<div class="label">{{ $t('create-user.workstation') }}</div>
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="value" :delay="{show: 100, hide: 0}" >
									<div class="value">{{ user.workstation.name }}</div>
									<template slot="popover">
										<div class="simple-text">{{ user.workstation.description }}</div>
									</template>
								</v-popover>
							</div>
							<div class="inline-data" v-if="user.date_of_employment">
								<div class="label">{{ $t('show_user.date-of-employment') }}</div>
								<div class="value">{{ user.date_of_employment | moment('DD MMM YYYY') }}</div>
							</div>
							<div class="inline-data" v-if="user.vacation_days">
								<div class="label">{{ $t('show_user.vacations-days') }}</div>
								<div class="value">{{ user.vacation_days }}</div>
							</div>

							<div class="subtitle-accordion">{{ $t('show_user.personal-data') }}</div>

							<div class="inline-data" v-if="user.business_email">
								<div class="label">{{ $t('show_user.email') }}</div>
								<div class="value">{{ user.business_email }}</div>
							</div>
							<div class="inline-data" v-if="user.business_phone">
								<div class="label">{{ $t('show_user.phone') }}</div>
								<div class="value">{{ user.business_phone }}</div>
							</div>
							<div class="inline-data" v-if="user.date_of_birth">
								<div class="label">{{ $t('show_user.data-of-birth') }}</div>
								<div class="value">{{ user.date_of_birth | moment('DD MMM YYYY') }}</div>
							</div>
							<div class="inline-data" v-if="user.timezone">
								<div class="label">{{ $t('show_user.timezone') }}</div>
								<div class="value">{{ user.timezone }}</div>
							</div>
							<div class="inline-data">
								<div class="label">{{ $t('show_user.married') }}</div>
								<div class="value">{{ user.married ? $t('general.yes') : $t('general.no') }}</div>
							</div>
							<div class="inline-data">
								<div class="label">{{ $t('show_user.childrens') }}</div>
								<div class="value">{{ user.has_children ? $t('general.yes') : $t('general.no') }}</div>
							</div>

							<div class="subtitle-accordion" v-if="user.facebook_link || user.linkedin_link || user.instagram_link || user.twitter_link || user.tiktok_link || user.youtube_link">{{ $t('show_user.social-data') }}</div>
							<div class="inline-data" v-if="user.facebook_link">
								<div class="label">{{ $t('show_user.facebook') }}</div>
								<div class="value"><a target="_blank" :href="user.facebook_link">{{ user.facebook_link }}</a></div>
							</div>
							<div class="inline-data" v-if="user.linkedin_link">
								<div class="label">{{ $t('show_user.linkedin') }}</div>
								<div class="value"><a target="_blank" :href="user.linkedin_link">{{ user.linkedin_link }}</a></div>
							</div>
							<div class="inline-data" v-if="user.instagram_link">
								<div class="label">{{ $t('show_user.instagram') }}</div>
								<div class="value"><a target="_blank" :href="user.instagram_link">{{ user.instagram_link }}</a></div>
							</div>
							<div class="inline-data" v-if="user.twitter_link">
								<div class="label">{{ $t('show_user.twitter') }}</div>
								<div class="value"><a target="_blank" :href="user.twitter_link">{{ user.twitter_link }}</a></div>
							</div>
							<div class="inline-data" v-if="user.tiktok_link">
								<div class="label">{{ $t('show_user.tiktok') }}</div>
								<div class="value"><a target="_blank" :href="user.tiktok_link">{{ user.tiktok_link }}</a></div>
							</div>
							<div class="inline-data" v-if="user.youtube_link">
								<div class="label">{{ $t('show_user.youtube') }}</div>
								<div class="value"><a target="_blank" :href="user.youtube_link">{{ user.youtube_link }}</a></div>
							</div>

							<div class="subtitle-accordion" v-if="user.hobbies.length">{{ $t('show_user.hobbies') }}</div>
							<div class="data hobbies-data" v-if="user.hobbies.length">
								<div class="value">{{ user.hobbies.map((el) => { return el}).join(", ") }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="user.files.length">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseFiles">
						<div class="icon"><icon-file /></div>

						<div class="title">{{ $t('show_user.files') }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseFiles" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseFiles">
						<div class="box-radius with-files">
							<div class="files">
								<div class="file" v-for="file in user.files">
									<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="label" :delay="{show: 100, hide: 0}" :disabled="!file.expire_date">
										<a class="file-box" :href="file.file_path" target="_blank">
											<div class="title">
												<div class="icon"><icon-file /></div>
												<div class="name">{{ file.filename }}</div>
											</div>
											<div class="expire-date" v-if="file.expire_date">
												<span>{{ $t('create-user.date-file-expire') }} {{ file.expire_date | moment('DD MMM YYYY') }}</span>
											</div>
											<div class="expire-date" v-else>
												<span>{{ $t('create-user.not-file-expire') }}</span>
											</div>
										</a>
										<template slot="popover">
											<div class="simple-text">{{ file.emails ? file.emails.join(", ") : '' }}</div>
										</template>
									</v-popover>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="user.id == $auth.user().id && $auth.user().email == 'alex@tbf.ro'">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseCalendarSync">
						<div class="icon"><icon-date /></div>

						<div class="title">{{ $t('show_user.calendar_sync') }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseCalendarSync" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseCalendarSync">
						<div class="box-radius">
							<div class="data">
								<div class="value">{{ $auth.user().google_calendar ? $t('show_user.calendar_sync_ready') : $t('show_user.calendar_sync_desc') }}</div>
							</div>

							<div class="inline-data center">
								<div class="value">
									<button class="btn-tbf google-button" :class="[ $auth.user().google_calendar ? 'white-red' : 'white-blue' ]" @click="$auth.user().google_calendar ? $root.$emit('google_sign_out') : $root.$emit('google_sign_in')">
										<div class="icon">
											<img src="/build/images/google-calendar.svg">
										</div>
										<div class="text">{{ $auth.user().google_calendar ? $t('sync_calendar.unsync') : $t('sync_calendar.sync') }}</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseExport">
						<div class="icon"><icon-download /></div>
						<div class="title">{{ $t('show_user.export') }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseExport" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseExport">
						<div class="box-radius border-0">
							<div class="description">{{ $t('show_user.export_type_description') }}</div>
							<div class="inline-data">
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t('show_user.export_type_evaluations') }}</span>
									<input type="checkbox" value="evaluations" v-model="filtersPdf">
									<span class="checkmark"></span>
								</label>
							</div>
							<div class="inline-data">
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t('show_user.export_type_congratulations') }}</span>
									<input type="checkbox" value="congratulations" v-model="filtersPdf">
									<span class="checkmark"></span>
								</label>
							</div>
							<div class="inline-data">
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t('show_user.export_type_warnings') }}</span>
									<input type="checkbox" value="warnings" v-model="filtersPdf">
									<span class="checkmark"></span>
								</label>
							</div>
							<!-- <div class="inline-data">
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t('show_user.export_type_objectives') }}</span>
									<input type="checkbox" value="objectives" v-model="filtersPdf">
									<span class="checkmark"></span>
								</label>
							</div>
							<div class="inline-data">
								<label class="checkbox-tbf">
									<span class="text-checkbox">{{ $t('show_user.export_type_key_results') }}</span>
									<input type="checkbox" value="key_results" v-model="filtersPdf">
									<span class="checkmark"></span>
								</label>
							</div> -->
							<div class="inline-data">
								<div class="label">{{ $t('show_user.pdf') }}</div>
								<div class="value link" @click="downloadPdf">
									<div class="loader-spin" v-if="loadingExportPdf"></div>
									{{ $t('show_user.download_pdf') }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="space-right-custom"></div>
		</div>
	</div>	
</template>

<script>
	import IconSkill from '../../Icons/Skill'
	import IconWorkSchedule from '../../Icons/WorkSchedule'
	import IconGlobe from '../../Icons/Globe'
	import IconFile from '../../Icons/FilePdf'
	import IconSearch from '../../Icons/Search'
	import IconArrow from '../../Icons/Arrow'
	import IconUser from '../../Icons/User'
	import IconTrash from '../../Icons/Trash'
	import IconClose from '../../Icons/Close'
	import IconDownload from '../../Icons/Download'
	import IconEdit from '../../Icons/EditDots'
	import IconSettings from '../../Icons/Settings'
	import IconDate from '../../Icons/Date'
	import IconEditMobile from '../../Icons/Edit'
	import IconPlus from '../../Icons/Plus'
	import TabCongratulations from './Congratulations'
	import TabWarnings from './Warnings'
	import TabAssessments from './Assessments'
	import TabPromises from './Promises'
	import TabObjectives from './Objectives'
	import TabKeyResults from './KeyResults'
	import TabProcedures from './Procedures'
	import TabProcesses from './Processes'
	import TabResponsibilities from '../Roles/ResponsibilitiesCalendar'
	import TabListResponsibilities from '../Roles/ResponsibilitiesList'
	import AddEvaluation from '../UserDetails/Evaluation'
	import IconRole from '../../Icons/Role'
	import AddCongratulationAndWarning from '../UserDetails/CongratulationAndWarning'

	export default {
		components: {
			IconSkill,
			IconWorkSchedule,
			IconGlobe,
			IconFile,
			IconSearch,
			IconArrow,
			IconUser,
			IconTrash,
			IconClose,
			IconEdit,
			IconSettings,
			IconDownload,
			IconEditMobile,
			IconDate,
			IconPlus,
			IconRole,
			TabCongratulations,
			TabWarnings,
			TabAssessments,
			TabPromises,
			TabObjectives,
			TabKeyResults,
			TabProcedures,
			TabProcesses,
			AddEvaluation,
			AddCongratulationAndWarning,
			TabResponsibilities,
			TabListResponsibilities,
		},
		data() {
			return {
				loaded: false,
				loadedList: false,
				search_filter: '',
				queryFiltersCount: 0,
				extendFilters: false,
				filterRoles: [],
				selectedRoles: [],
				searchRoles: '',
				filterCategories: [],
				selectedCategories: [],
				searchCategories: '',
				procedures: [],
				sortBy: '',
				sortDirection: 'asc',
				user: {},
				activeTab: this.$route.query.tab ? this.$route.query.tab : 'responsibilities',
				activeForm: '',
				maxDateCalendar: moment().format('YYYY-MM-DD'),
				dateRange: {
					startDate: moment().add(-30, 'days'),
					endDate: moment()
				},
				calendarFilterSettings: {
					firstDay: 1,
					format: 'DD-MM-YYYY',
					daysOfWeek:  moment.weekdaysMin(),
					monthNames: moment.monthsShort()
				},
				activeProceduri: false,
				activeObiective: false,
				loadingExportPdf: false,
				filtersPdf: [],
				promiseObjective: { ready: false }
			}
		},
		computed: {
			sortedProcedures: function(){
				return this.procedures.sort((p1,p2) => {
					let modifier = 1;
					if(this.sortDirection === 'desc') modifier = -1;
					if(p1[this.sortBy] < p2[this.sortBy]) return -1 * modifier; if(p1[this.sortBy] > p2[this.sortBy]) return 1 * modifier;
					return 0;
				});
			},
			filteredProcedures() {
				return getByKeyword(this.sortedProcedures, this.search_filter)
			},
			filteredRoles(){
				return getByKeywordFilter(this.filterRoles, this.searchRoles)
			},
			soft_applications() {
				return this.$store.getters['applications/activeApplications']
			}
		},
		watch: {
			search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			},
			dateRange: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['startDate'] = moment(this.dateRange.startDate).format('DD-MM-YYYY');
				data['endDate'] =  moment(this.dateRange.endDate).format('DD-MM-YYYY');

				this.$router.push({query : data });
			},
			soft_applications(newData, oldData) {
				newData.map((el) => {
					if(el.application){
						if(el.application.name == 'Proceduri'){
							this.activeProceduri = el.is_active
						}
						if(el.application.name == 'Obiective'){
							this.activeObiective = el.is_active
							this.getObjectivePromise()
						}
					}
				})
			}
		},
		async mounted(){

			await this.checkQueryFilters()
			await this.getUserData()
			await this.getFilters()

			setTimeout(() => {
				var title = this.user.first_name + ' ' + this.user.last_name;
				this.$root.$emit("navbar_title", title);

				if(this.$route.query.tab) {
					this.activeTab = this.$route.query.tab;
					this.activeForm = this.$route.query.tab;
				}
			}, 0)


			if(this.soft_applications.length) {
				this.soft_applications.map((el) => {
					if(el.application){
						if(el.application.name == 'Proceduri'){
							this.activeProceduri = el.is_active
						}
						if(el.application.name == 'Obiective'){
							this.activeObiective = el.is_active
							if(el.is_active) {
								this.getObjectivePromise()
							}
						}
					}
				})
			}
		},
		methods: {
			async getUserData(){
				await axios.get(`/users/${this.$route.params.slug}`)
				.then(({data}) => {
					this.user = data.data
					this.procedures = data.data.procedures
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})		
			},
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {roles: true, categories: true }})
				.then(({data}) => {
					this.filterRoles = data.data.roles
					this.filterCategories = data.data.categories
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
			showProcedure(itemSlug){
				this.$router.push({name: 'procedure-show', params: {slug: itemSlug}})
			},
			selectOptionFilter(filter, value) {
				if(typeof value == 'string'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
				} else {
					if(this[filter].includes(parseInt(value))){
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
				}

				this.addFilterToRouteQuery(filter)
			},
			addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedRoles' || filterName == 'all'){ dataQuery['roles'] = this.selectedRoles.toString(); }
				if(filterName == 'selectedCategories' || filterName == 'all'){ dataQuery['categories'] = this.selectedCategories.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length

				this.$router.push({query : dataQuery });
			},
			clearFilter(filter){
				if(filter == 'all'){
					this.selectedRoles = []
					this.selectedCategories = []
				}else{
					this[filter] = []
				}

				this.addFilterToRouteQuery(filter)
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'startDate'){ this.dateRange.startDate = moment(dataQuery[key], 'DD-MM-YYYY') }
					if(key == 'endDate'){ this.dateRange.endDate = moment(dataQuery[key], 'DD-MM-YYYY') }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length
			},
			sortList(column){
				if(column === this.sortBy) {
					this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
				}
				this.sortBy = column;
			},
			editProcedure(procedureSlug){
				this.$router.push({ name: 'procedure-edit', params: {slug: procedureSlug} })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			goCreateEntry(item){
				this.activeForm = item;
			},
			changeTab(item) {
				this.selectedRoles = []
				this.$router.push({name: 'user-show', query: {tab: item}})
				this.activeForm = ''
			},
			downloadPdf(){
				this.loadingExportPdf = true

				axios.get(`reports/${this.user.slug}/user-pdf`, 
				{
					params: { filter: this.filtersPdf.join(",") },
                	responseType: 'blob'
				})
				.then((response) => {

					let blob = new Blob([response.data], { type:"application/octet-stream" })
					let fileURL = window.URL.createObjectURL(blob);

					let fileName = `${this.user.first_name} ${this.user.last_name ? this.user.last_name : ''}.pdf`;

					let fileLink = document.createElement('a');
					fileLink.href = fileURL;
					fileLink.setAttribute( 'download', fileName );
					document.body.appendChild(fileLink);

					fileLink.click();

					setTimeout(() => {
						this.loadingExportPdf = false
					}, 100)
				})
			},
			getObjectivePromise() {
				axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/sync-data/${this.user.slug}/check-promise`)
				.then(({data}) => {
					this.promiseObjective = {...data.data, ready: true}
				})
			}
		}
	}
	function getByKeyword(list, keyword) {
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => (item.name).toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
			return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
@import "../../../styles/pages/user_show.scss";
.filter-btns{
	display: flex;
	align-items: center;
	margin-right: 20px;
	&.mr-0{
		margin-right: 0;
	}
	.btn-default-tbf{
		height: 36px;
		font-size: 12px;
		font-weight: 500;
		color: $primary;
		padding: 0 15px;
		min-width: 110px;
		text-align: left;
		display: flex;
		align-items: center;
		.icon-arrow{
			margin-left: auto;
		}
		.clearFilter{
			margin-left: auto;
			height: 12px;
			margin-right: -2px;
		}
	}
	.calendar-filter{
		margin-left: 20px;
	}
	.filter-status{
		margin-left: 20px;
	}
}
.input-picker{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	.icon{
		margin-right: 10px;
		justify-content: center;
		display: flex;
		svg{
			height: 16px;
			width: auto;
			.fill-color{
				fill: $placeholderColor;
			}
		}	
	}
	.text{
		color: $placeholderColor;
	}
	.date{
		flex: 1;
		font-size: 12px;
		color: $primary;
		text-transform: capitalize;
		line-height: normal;
	}
}
.responsibility{
	margin: 0 0 10px 0;
	padding-left: 15px;
	line-height: 1.3;
	margin-top: 10px;
}
.filter-dropdown{
	&.second,
	&.third{
		margin-left: 20px;
		button{
			height: 36px !important;
		}
		@media (max-width: 1065px) {
			max-width: 100px !important;
		}
	}
}
.vue-daterange-picker{
	&.mobile {
		.form-control{
			height: 36px !important;
		}
	}
}
.btn-tbf{
	&.show {
		height: 36px !important;
	}
	&.drop {
		min-width: 100px !important;
	}
}
</style>