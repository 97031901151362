<template>
	<div class="data-list list-tab-show-page">
		<template v-if="loaded">
			<div class="row-space-tbf header-list">
				<div class="space-left"></div>
				<div class="content">
					<div class="column-filter column-name">
						<div class="text">{{ $t('general.title') }}</div>
					</div>
					<div v-if="$resize && ($mq.above(600) && !$mq.between([1025, 1225]))" class="column-filter column-status-obj">
						<div class="text">{{ $t('general.status') }}</div>
					</div>
					<div v-if="$resize && $mq.above(500)" class="column-filter column-deadline">
						<div class="text">{{ $t('show_user.deadline') }}</div>
					</div>
					<div v-if="$resize && ($mq.above(635) && !$mq.between([1025, 1305]))" class="column-filter column-progress-obj">
						<div class="text">{{ $t('show_user.progress') }}</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>

			<template v-if="entries.length">
				<div class="row-space-tbf row-list-item" :key="'objective' + entry.slug" v-for="entry in entries">
					<div class="space-left"><icon-objectives v-if="$resize && $mq.above(781)"/></div>
					<div class="content">
						<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
						<div class="column-name-title column-name">
							<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover" :delay="{show: 0, hide: 0}">
								<div class="name link" @click.stop="goItem(entry.slug)">{{ entry.name }}</div>
								<template slot="popover">
									<div class="title">{{ entry.name }}</div>
									<div class="info-text">{{ entry.description }}</div>
								</template>
							</v-popover>
						</div>
						<div v-if="$resize && ($mq.above(600) && !$mq.between([1025, 1225]))" class="column-simple-text column-status-obj">
							<div class="text">{{ $t(`show_user.objectives_status.${entry.status}`) }}</div>
						</div>
						<div v-if="$resize && $mq.above(500)" class="column-simple-text column-deadline">
							<div class="text">{{ entry.end_date | moment('DD MMM YYYY') }}</div>
						</div>

						<div class="column-value-progress column-progress-obj" v-if="$resize && ($mq.above(635) && !$mq.between([1025, 1305]))">
							<div class="top-data">
								<div class="current-value">
									<div class="value" v-if="moment(entry.start_date) >= moment()">0</div>
									<div class="value" v-else>{{ parseInt(entry.percent).toString() + '%' }}</div>
								</div>
								<div class="icon-info"><icon-info /></div>
							</div>
							<div class="progress-bar-tbf" v-bind:class="{disabled: moment(entry.start_date) > moment()}">
								<div class="complete-progress-tbf" v-bind:class="{pink: entry.status == 'overdue', green: entry.percent == 100}" :style="'width: calc(2px + ' + (parseInt(entry.percent) < 1 ? '2px' : entry.percent + '%') + ');'"></div>
							</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</template>
			<template v-else>
				<div class="row-space-tbf row-list-item">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-empty-list">
							<div class="name"><img src="/build/images/no-results-found-inline.svg">{{ $t('congratulations.empty-inline', {name: userName }) }}</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>
			</template>
		</template>
		<loader-items-list-2 v-else/>
	</div>
</template>


<script>
	import IconUser from '../../Icons/User'
	import IconEdit from '../../Icons/EditDots'
	import IconObjectives from '../../Icons/Objectives'
	import IconEditMobile from '../../Icons/Edit'
	import IconInfo from '../../Icons/Info'
	import LoaderItemsList2 from '../../PagesLoaders/ItemsList2'
	import StarRating from 'vue-star-rating'

	export default {
		components: {
			IconUser,
			IconEdit,
			IconEditMobile,
			IconObjectives,
			LoaderItemsList2,
			IconInfo,
			StarRating
		},
		data() {
			return {
				loaded: false,
				entries: []
			}
		},
		props: {
			userName: String,
			userSlug: String,
			dateRange: Object
		},
		watch:{
			$route (to, from){
				this.getObjectivesEntries()
			}
		},
		async mounted(){
			await this.getObjectivesEntries()
		},
		methods: {
			async getObjectivesEntries(){
				let paramsRequest = { 
					start_date: moment(this.dateRange.startDate).format('DD-MM-YYYY'),
					end_date: moment(this.dateRange.endDate).format('DD-MM-YYYY')
				}

				await axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/sync-data/${this.userSlug}/objectives`, {params: paramsRequest })
				.then(({data}) => {
					this.entries = data.data
				})
				.finally(() => {
					setTimeout(() => { this.loaded = true }, 0)
				})
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			goItem(itemSlug){
				window.open(`https://obiective.tbf.ro/obiectiv/${itemSlug}`, '_blank');
			}
		}
	}
</script>